import {
  HomeIcon,
  ViewGridIcon,
  BellIcon,
  VideoCameraIcon,
  RewindIcon,
  ShoppingBagIcon,
  BeakerIcon,
  OfficeBuildingIcon,
  CubeTransparentIcon,
  CalendarIcon,
  UserCircleIcon,
  PaperAirplaneIcon,
  ViewBoardsIcon,
} from '@heroicons/react/outline'
import { toggleOrderModal } from '../../store/slices/modalSlice'
import { store } from '../../store'
// import {faPati} from "@fortawesome/free-solid-svg-icons"

export const sidebarNavigation = [
  { name: 'Home', icon: HomeIcon, href: '/' },
  {
    name: 'Consult',
    icon: VideoCameraIcon,
    href: '/patients/register',
  },
  { name: 'Vitals', icon: PaperAirplaneIcon, href: '/vitals' },
  { name: 'History', icon: RewindIcon, href: '/patients/history' },
  {
    name: 'Diagnostics',
    icon: CalendarIcon,
    href: '/diagnostics',
    soon: true,
  },
  {
    name: 'Track',
    icon: CubeTransparentIcon,
    onClick: () => store.dispatch(toggleOrderModal()),
  },
  {
    name: 'Appointments',
    icon: ViewBoardsIcon,
    href: '/video-appointment/book',
    glow: true,
  },
  { name: 'Stats', icon: ViewGridIcon, href: '/stats' },
  { name: 'Alerts', icon: BellIcon, href: '/alerts', soon: true },
  { name: 'Mart', icon: OfficeBuildingIcon, href: '/mart' },
  { name: 'Products', icon: ShoppingBagIcon, href: '/products' },
  // { name: 'Tests', icon: BeakerIcon, href: '/tests' ,soon:true},
  { name: 'Profile', icon: UserCircleIcon, href: '/profile' },
]

export const navigation = [
  {
    name: 'Inboxes',

    children: [
      { name: 'Technical Support' },
      { name: 'Sales' },
      { name: 'General' },
    ],
  },
  { name: 'Reporting', children: [] },
  { name: 'Settings', children: [] },
]

export const frequency = [
  { name: 'Every Hour', value: 24 },
  { name: 'Once in a Day', value: 1 },
  { name: '2 times a day', value: 2 },
  { name: '3 times a day', value: 3 },
  { name: '4 times a day', value: 4 },
  { name: 'SOS', value: 'SOS' },
]

export const interval = [
  'Daily',
  'Alternate Day',
  'Once a Week',
  'Once in 2 Week',
  'Twice in a Week',
  'Monthly',
]

export const time_slots = [6, 8, 10, 12, 14, 16, 18, 20, 22]

export const medicineTypeList = [
  'Tab',
  'Capsule',
  'Lotion',
  'Syrup',
  'Ointment',
  'Injection',
  'Lozenges',
  'Satchet',
]
