import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { store } from '..'
import axios from '../../utils/axios'
import { notifier } from './notificationSlice'

// declaring the types for our state
export type ClientAppointmentState = {
  loading: boolean
  error: any | null
  data: {
    appointments: any | null
    total: any
    current: any
  }
  memberData: any | null
  appointmentData: any | null
  clinicData: any | null
  searchData: any | null
  appointmentSelectedId: any | null
}

const initialState: ClientAppointmentState = {
  error: null,
  loading: true,
  data: {
    appointments: [],
    total: 0,
    current: 0,
  },
  memberData: null,
  appointmentData: [],
  clinicData: null,
  searchData: null,
  appointmentSelectedId: [],
}

export const appointmentSlice = createSlice({
  name: 'appointment',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions.
  // In this example, 'increment', 'decrement' and 'incrementByAmount' are actions. They can be triggered from outside this slice, anywhere in the app.
  // So for example, if we make a dispatch to the 'increment' action here from the index page, it will get triggered and change the value of the state from 0 to 1.
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setAppointmentData: (state, action: PayloadAction<any>) => {
      state.loading = false
      state.data.appointments = action.payload
    },
    setCurrent: (state, action: PayloadAction<any>) => {
      state.loading = false
      state.data.current = action.payload
    },
    setTotal: (state, action: PayloadAction<any>) => {
      state.loading = false
      state.data.total = action.payload
    },
    setError: (state, action: PayloadAction<any>) => {
      state.loading = false
      state.error = action.payload
    },
    setMemberData: (state, action: PayloadAction<any>) => {
      console.log('Member Data >>> ', action.payload)
      state.memberData = action.payload
    },
    setTestData: (state, action: PayloadAction<any>) => {
      state.appointmentData = action.payload
    },
    setClinicData: (state, action: PayloadAction<any>) => {
      state.clinicData = action.payload
    },
    setSearchData: (state, action: PayloadAction<any>) => {
      state.searchData = action.payload
    },
    setTestSelectedId: (state, action: PayloadAction<any>) => {
      state.appointmentSelectedId = action.payload
    },
  },
})
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const {
  setLoading,
  setAppointmentData,
  setError,
  setMemberData,
  setTestData,
  setClinicData,
  setCurrent,
  setTotal,
  setTestSelectedId,
} = appointmentSlice.actions

//API Calls

export const getAppointmentList =
  (val: any) =>
  async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    try {
      dispatch(setLoading(true))
      const response = await axios.get(
        `appointmentByClinic?page=${val}&speciality=true`
      )
      const data = response.data.response.data
      dispatch(setAppointmentData(data))
    } catch (error) {
      dispatch(setError(error))
    }
  }

export const bookAppointment = (values: any) => async (dispatch) => {
  try {
    console.log('values>>>>', values)
    const res = await axios.post('/videoConsultationAppointment', values)
    const data = res.data.response.data
    const booked = store.getState().appointment.data.appointments
    // @ts-ignore
    //    dispatch(setBookedData([data, ...booked]))
    notifier.success('Appointment Booked Successfully !')
    // this.props.history.push('/appointment')
  } catch (err) {
    console.error(err)
  }
}

export const updateAppointment = (id: any, values: any) => async (dispatch) => {
  try {
    const res = await axios.patch(`/appointment/${id}`, values)
    const data = res.data.response.data
  } catch (err) {
    console.error(err)
  }
}

//-----------------------------------------AppointMent Search Detail Call----------------------------------------------------
export const getAppointMentSearchDetail =
  (id: any) => async (dispatch: (arg0: { payload: any }) => void) => {
    try {
      dispatch(setLoading(true))
      let response = await axios.get(
        `appointmentByClinic/paginate?page=1&limit=10&order_id=${id}`
      )
      const data = response.data.response.data
      const bookedData = data?.booked?.docs
      dispatch(setAppointmentData(bookedData))
    } catch (error) {
      dispatch(setError(error))
    }
  }

// exporting the reducer here, as we need to add this to the store
export default appointmentSlice.reducer
